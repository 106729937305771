var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ee5521d77a93e25f44c7db066aa7a1bfc4b73166"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import type { ServerResponse } from '@wello-client/common/utils';

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://53c4297eec469bc3d0667ca15c457906@o4506703883665408.ingest.sentry.io/4506703886680064',
  beforeSend: (event) => {
    const userAgent = event.request?.headers?.['User-Agent'];
    const errorValue = event.exception?.values?.[0].value;

    if (userAgent) {
      const isKbWebView = userAgent.toLowerCase().includes('liivmate');

      //* 🌱 KB Pay 웹뷰에서 웰로에는 없는 메소드들을 호출하는 경우들이 있음
      if (isKbWebView) {
        if (errorValue) {
          const lowerCaseErrorValue = errorValue.toLowerCase();
          const exceptionTextList = ['resume', 'undefined'];

          if (
            exceptionTextList.some((text) => lowerCaseErrorValue.includes(text))
          )
            return null;
        }
      }
    }

    const normalizedError = event.extra?.__serialized__ as
      | ServerResponse
      | undefined;

    const isNormalizedError = !!normalizedError?.return_code;

    if (isNormalizedError) {
      const { return_code, return_message } = normalizedError;

      event.message = return_message;
      const code = return_code ?? 40000;

      event.tags = {
        ...event.tags,
        code,
        createdOn: 40000 <= code ? 'FRONTEND' : 'BACKEND',
      };
    } else {
      event.tags = {
        ...event.tags,
        createdOn: 'UNKNOWN',
      };
    }

    return event;
  },

  tracesSampleRate: 0.1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],
});
